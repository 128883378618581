@font-face {
    font-family: 'Circe';
    src: url(./Fonts/SBPCirce-Regular.otf) format('truetype')
}

@font-face {
    font-family: 'Circe-bold';
    src: url(./Fonts/SBPCirce-Bold.otf) format('truetype')
}

@font-face {
    font-family: 'Circe-Light';
    src: url(./Fonts/SBPCirce-Light.otf) format('truetype')
}

html,
body,
#root {
    height: 100%;
}

#root {
    background-color: #190940;
}

.layout {
    background-color: #190940;
    margin: 0;
    height: 100%
}

.game-layout {
    width: 100%;
    height: 100%;
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #190940;
}

.game-area {
    position: relative;
    width: 87%;
    max-width: 915px;
    height: 100%;
    background-color: #190940;
}

.game-area__header {
    margin: 0px auto;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.momgame-layout {
    background-color: #190940;
    width: 100%;
    height: 100%;
    animation: momgame-color 1s forwards;
}

@keyframes momgame-color {
    0% {
        background-color: #190940;
    }

    100% {
        background-color: #e50050;
    }
}

.momgame-aligner {
    height: 70px;
    background-color: #190940;
    animation: momgame-color 1s forwards;
}

.game-area__aligner {
    width: 100%;
    height: 100%;
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #190940;
}

.start-page__layout {
    background-color: #190940;
    height: 100%;
    width: 100%;
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    ;
}

.start-page {
    width: 100%;
    background-color: #190940;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.start-page__title {
    position: relative;
    font-family: Circe-bold;
    text-align: center;
    font-weight: bold;
    line-height: 1;
    font-size: 50px;
    max-width: 462px;
    color: #ffffff;
    margin-bottom: 23px;
}

.start-page__description {
    font-family: Circe;
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 405px;
    margin-bottom: 30px;
}

.start-page__description a {
    color: white;
}

.start-page__description br {
    font-size: 12px;
}

.start-page__start-button {
    position: relative;
    display: block;
    padding: 0;
    border: none;
    width: 238px;
    height: 100px;
    border-radius: 100px;
    background-color: #fbbb20;
    cursor: pointer;
    font-size: 23px;
    font-family: Circe-bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.start-page__start-button:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 99%;
    left: 55%;
    border-top: 14px solid #fbbb20;
    border-left: 35px solid transparent
}

.chat {
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chat__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12%;
    min-height: 45px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    font-family: Circe-bold;
    font-size: 21px;
    z-index: 10;
}

.chat__contact-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ee7225;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
}

.chat__contact-icon.momgame-icon {
    background-color: #f0858f;
}

.chat__messages-wrapper {
    overflow-y: scroll;
    max-height: 80%;
}

.chat__messages-wrapper::-webkit-scrollbar {
    display: none;
}

.chat__message {
    position: relative;
    background-color: #efeadc;
    padding-top: 18px;
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 10px;
    border-radius: 20px;
    width: fit-content;
    max-width: 70%;
    margin-left: 13px;
    margin-top: 10px;
    animation: fadeNewMessage 0.8s;
    font-size: 18px;
}

.chat__scam-integration-message {
    margin: 30px auto;
    background-color: #F0E68C;
    text-align: center;
    font-family: Circe-bold;
    animation: next-button-appear 0.5s;
    font-size: 18px;
    padding-top: 12px;
}

.chat__message:first-of-type {
    margin-top: 30px;
}

.chat__message_align-right {
    background-color: #63b22e;
    margin-left: auto;
    margin-right: 10px;
    color: #ffffff;
}

.chat__message-text {
    font-family: Circe;
    font-size: 18px;
    line-height: 1.22;
}

.chat__message-time {
    margin-top: 6px;
    width: fit-content;
    margin-left: auto;
    font-size: 13px;
    opacity: 0.3;
}

.chat__answers-buttons-aligner {
    margin-bottom: -40px;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.chat__answers-buttons-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.chat__answers-buttons-wrapper.chat__checkbox-answers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #63b22e;
    width: 320px;
    border-radius: 45px;
    animation: next-button-appear 0.5s forwards;
}

.chat__checkbox-answers-wrapper:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 99.5%;
    left: 60%;
    border-top: 14px solid #63b22e;
    border-left: 35px solid transparent
}

.chat__answer-button.chat__checkbox-answer-button {
    height: 53px;
    margin-top: 20px;
    background-color: #017f36;
}

.chat__checkboxes-wrapper {
    margin-top: 28px;
    font-family: Circe-bold;
    font-size: 23px;
    width: fit-content;
    border: none;
    display: flex;
    flex-direction: column;
}

.chat__checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    cursor: pointer;
    height: 0;
}

.chat__checkbox-wrapper {
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    user-select: none;
}

.chat__checkbox-wrapper:last-of-type {
    margin-bottom: 0;
}

.chat__checkbox-checkmark {
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin-right: 13px;
}

.chat__checkbox-wrapper input:checked~.chat__checkbox-checkmark {
    background-color: #ffffff;
}

.chat__answer-button {
    position: relative;
    display: inline-block;
    color: #ffffff;
    width: 200px;
    height: 77px;
    border: none;
    border-radius: 70px;
    font-size: 18px;
    font-family: Circe-bold;
    margin: 5px 0;
    margin: 0 10px;
    animation: next-button-appear 0.5s forwards;
    cursor: pointer;
}

.chat__answer-button:first-of-type:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 76px;
    left: 55%;
    border-top: 14px solid rgb(99, 178, 46);
    border-left: 35px solid transparent
}

.chat__answer-button:nth-of-type(2):after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 76px;
    left: 55%;
    border-top: 14px solid rgb(229, 0, 80);
    border-left: 35px solid transparent
}

.chat__checkbox-answer-button {
    position: relative;
    background-color: #017f36;
    display: inline-block;
    color: #ffffff;
    width: 200px;
    height: 53px;
    border: none;
    border-radius: 70px;
    font-size: 18px;
    font-family: Circe-bold;
    margin: 5px 0;
    margin: 0 10px;
    width: 85%;
    font-size: 23px;
    margin-bottom: 15px;
    margin-top: 20px;
    cursor: pointer;
}

.chat__checkbox-answer-button:focus {
    outline: none;
    user-select: none;
}

.chat__answers-next-button {
    display: block;
    background-color: #fbbb22;
    color: #ffffff;
    width: 200px;
    height: 77px;
    border: none;
    border-radius: 70px;
    font-size: 18px;
    opacity: 0;
    font-family: Circe-bold;
    animation: next-button-appear 0.5s forwards;
    animation-delay: 0.7s;
    cursor: pointer;
}

.chat__answers-next-button:focus {
    outline: none;
    user-select: none;
}

.chat__answer-button:focus {
    outline: none;
    user-select: none;
}

.chat__answer-button:active {
    background-color: white;
}

.result {
    background-color: #190940;
    padding: 30px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.result__title {
    position: relative;
    font-size: 50px;
    font-family: Circe-bold;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ffffff;
    margin-top: 15px;
    line-height: 1;
}

.result__text {
    font-family: Circe;
    font-size: 18px;
    line-height: 1.22;
}

.result__description {
    color: #ffffff;
    max-width: 405px;
}

.result__positive-mistake {
    margin: 20px;
    color: green;
    max-width: 405px;
    margin-bottom: 0px;
}

.result__negative-mistake {
    margin: 20px;
    color: red;
    max-width: 405px;
    margin-bottom: 0px;
}

.result__restart-button {
    display: block;
    background-color: #190940;
    color: #ffffff;
    height: 4vh;
    border: none;
    margin: 20px auto;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.result__restart-button:focus {
    outline: none;
    user-select: none;
}

.result__share-button {
    display: block;
    padding: 0;
    border: none;
    width: 238px;
    height: 105px;
    border-radius: 100px;
    background-color: #fbbb22;
    cursor: pointer;
    font-size: 23px;
    font-family: Circe-bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    user-select: none;
    line-height: 1;
}

.result__share-button:focus {
    outline: none;
    user-select: none;
}




.result__share-button:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 99%;
    left: 55%;
    border-top: 14px solid #fbbb22;
    border-left: 35px solid transparent
}

.result__share-button-wrapper {
    position: relative;
    height: 105px;
    margin-top: 30px;
}

.result__opened-share-button {
    left: -119px;
    bottom: 0px;
    position: absolute;
    display: block;
    padding: 0;
    border: none;
    width: 238px;
    height: 224px;
    border-radius: 45px;
    background-color: #fbbb22;
    cursor: pointer;
    font-size: 23px;
    font-family: Circe-bold;
    user-select: none;
}

.result__opened-share-button:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 224px;
    left: 55%;
    border-top: 14px solid #fbbb22;
    border-left: 35px solid transparent
}

.result__close-button {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 14px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.result__close-button svg {
    width: 16px;
    height: 16px;
    fill: white;
}

.result__share-option {
    display: block;
    color: #ffffff;
    font-family: Circe-bold;
    font-size: 23px;
    margin: 14px auto;
    text-decoration: none;
}

.result__share-option:first-of-type {
    margin-top: 36px;
}

.start-page__start-button:focus {
    outline: none;
    user-select: none;
}

.progress {
    width: 90px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.progress__text {
    font-size: 25px;
    font-family: Circe-bold;
    color: #fab719;
}

.progress__icon {
    margin-left: 5px;
    height: 15px;
    width: 14.5px;
}

.money {
    width: 83px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.result .money {
    margin-top: 40px;
}

.money__text {
    font-size: 25px;
    font-family: Circe-bold;
    color: #63B22E;
}

.money__icon {
    height: 15px;
    width: 12px;
    margin-left: 5px;
}

.timer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 40px;
}

.timer__circle-1 {
    position: absolute;
    width: 108px;
    height: 108px;
    background-color: rgba(91, 88, 157, 0.5);
    border-radius: 100%;
    top: -40px;
    left: -40px;
}

.timer__circle-2 {
    position: absolute;
    width: 210px;
    height: 210px;
    border-radius: 100%;
    top: -90px;
    left: -90px;
    background-color: rgba(91, 88, 157, 0.25);
    ;
}

.timer__circle-3 {
    position: absolute;
    width: 310px;
    height: 310px;
    border-radius: 100%;
    top: -140px;
    left: -140px;
    background-color: rgba(91, 88, 157, 0.25);
    ;
}

.timer__counter {
    font-size: 25px;
    font-family: Circe-bold;
    color: #ffffff;
    z-index: 10;
    margin-left: auto;
    margin-right: 5px;
}

.timer__icon {
    height: 15px;
    width: 13px;
    margin-left: auto;
    margin-right: 0px;
    z-index: 10;
}

.chat__questionTypingDot-wrapper {
    width: 15%;
    margin-left: 13px;
    margin-top: 5px;
    height: 17px;
}

.chat__answerTypingDot-wrapper {
    width: 15%;
    margin-left: auto;
    margin-right: 0px;
    height: 17px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 35px;
}

.chat__typingDot {
    position: relative;
    left: 4px;
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #606060;
    border-radius: 2px;
    animation: typingMessage 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.chat__typingDot::before,
.chat__typingDot::after {
    content: '';
    position: absolute;
    display: inline-block;
    left: 6px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #606060;
    animation: typingMessage 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.chat__typingDot::before {
    left: 6px;
    animation-delay: 0.5s;
}

.chat__typingDot::after {
    left: 12px;
    animation-delay: 1s;
}

.top-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-icons__mel-icon {
    display: block;
    width: 84px;
    height: 24px;
}

.top-icons__sbp-icon {
    display: block;
    width: 110px;
    height: 55px;
}

.top-icons__divider {
    width: 2px;
    height: 46px;
    margin-left: 25px;
    margin-right: 25px;
    background-color: rgba(255, 255, 255, 0.2);
}

.start-page .top-icons {
    margin-bottom: 37px;
}

.momgame {
    max-width: 915px;
    margin: 0px auto;
}

.momgame__messages-wrapper {
    height: 80%;
}

.momgame__messages-wrapper .chat__message_align-right {
    background-color: #f18e97;
}

.momgame__messages-wrapper .chat__message {
    padding-bottom: 18px;
}

.momgame__image {
    height: 350px;
    width: 350px;
}

.momgame__answer-button {
    position: relative;
    display: inline-block;
    color: #ffffff;
    width: 200px;
    height: 77px;
    border: none;
    border-radius: 70px;
    font-size: 18px;
    font-family: Circe-bold;
    margin: 5px 0;
    margin: 0 10px;
    background-color: #f18e97;
    cursor: pointer;
}

.momgame__answer-button:focus {
    outline: none;
    user-select: none;
}

.momgame__answer-button.triangle:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 99%;
    left: 55%;
    border-top: 14px solid #f18e97;
    border-left: 35px solid transparent
}

.chat__message.momgame__message-gif {
    background-color: #ffffff;
    padding: 0;
}

.after-scam-integration__layout {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: #190940;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.after-scam-integration__text {
    font-family: Circe;
    font-size: 23px;
    text-align: center;
    color: #ffffff;
}

.integration__text a {
    color: white;
}

.integration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #190940;
}

.integration__logo {
    display: block;
    width: 110px;
    height: 55px;
    margin-top: 60px;
    margin-bottom: 121px;
}

.integration__title {
    font-family: Circe-bold;
    font-size: 50px;
    color: #ffffff;
    margin-bottom: 20px;
}

.integration__text {
    max-width: 405px;
    font-family: Circe;
    font-size: 18px;
    line-height: 1.22;
    color: #ffffff;
    text-align: center;
    margin: 0px 20px;
    margin-bottom: 120px;
}

.integration__next-button {
    display: block;
    padding: 0;
    border: none;
    width: 200px;
    height: 77px;
    border-radius: 100px;
    background-color: #63b22e;
    cursor: pointer;
    font-size: 18px;
    font-family: Circe-bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 30px;
    cursor: pointer;
}

.integration-layout {
    height: 100%;
    display: flex;
}

.result-layout {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #190940;
}

.integration__next-button:focus {
    outline: none;
    user-select: none;
}

.balloons__layout {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1400px;
    overflow: hidden;
    margin: 0 auto;
}

.balloons__balloon {
    position: absolute;
    animation: bubble-rotating 9s infinite ease-in,
        floatBubble 5s 1 linear forwards;
    transition: opacity 1s;
    user-select: none;
}

.balloon-popped {
    animation: pop 2ms forwards;
}

@keyframes pop {
    50% {
        transform: scale(1.2);
    }

    100% {
        opacity: 0;
    }
}

.balloons__start-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #190940;
}

.balloons__title {
    position: relative;
    font-family: Circe-bold;
    font-size: 50px;
    color: #ffffff;
    margin-bottom: 23px;
    text-align: center;
    max-width: 462px;
    line-height: 1;
}

.balloons__description {
    font-family: Circe;
    font-size: 18px;
    color: #ffffff;
    line-height: 1.22;
    text-align: center;
    max-width: 405px;
}

.balloons__start-button {
    margin-top: 30px;
    animation: float 10s infinite;
    cursor: pointer;
}

.balloons__start-page__red-balloon {
    position: absolute;
    width: 161px;
    height: 276px;
    top: 0px;
    left: -250px;
    animation: float 7s infinite;
}

.balloons__start-page__green-balloon {
    position: absolute;
    width: 161px;
    height: 276px;
    top: -150px;
    right: -250px;
    animation: float 4s infinite;
}

.balloons__result-wrapper {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #190940;
    overflow-y: hidden;
}

.balloons__result-text {
    position: relative;
    z-index: 10;
    font-family: Circe-bold;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    width: 59%;
    max-width: 615px;
    line-height: 1;
}

.balloons__result-balloon1 {
    z-index: 0;
    position: absolute;
    top: -110px;
    right: -150px;
}

.balloons__result-balloon2 {
    z-index: 0;
    position: absolute;
    top: 220px;
    left: -150px;
}

.balloons__result-balloon3 {
    z-index: 0;
    position: absolute;
    top: 560px;
    right: 0px;
}

.balloons__next-button {
    margin-top: 90px;
    display: block;
    padding: 0;
    border: none;
    width: 200px;
    height: 77px;
    border-radius: 100px;
    background-color: #fbbb22;
    cursor: pointer;
    font-size: 18px;
    font-family: Circe-bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.balloons__next-button:focus {
    outline: none;
    user-select: none;
}

.button-inner-elements {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-inner-elements svg {
    height: 30px;
    width: 30px;
    margin-left: 30px;
}

.floating-ruble {
    position: absolute;
}

.floating-ruble__1 {
    width: 42px;
    height: 52px;
    top: -140px;
    left: -98px;
    animation: float 6s ease-in-out infinite;
}

.floating-ruble__2 {
    width: 33px;
    height: 41px;
    top: 0px;
    left: -249px;
    animation: float 5s ease-in-out infinite;
}

.floating-ruble__3 {
    width: 111px;
    height: 136px;
    top: 68px;
    left: -178px;
    animation: float 4s ease-in-out infinite;
}

.floating-ruble__4 {
    width: 33px;
    height: 41px;
    top: 297px;
    left: -89px;
    animation: float 5s ease-in-out infinite;
}

.floating-ruble__5 {
    width: 57px;
    height: 71px;
    left: -198px;
    top: 427px;
    animation: float 6s ease-in-out infinite;
}

.floating-ruble__6 {
    width: 69px;
    height: 85px;
    right: -220px;
    top: -131px;
    animation: float 4s ease-in-out infinite;
}

.floating-ruble__7 {
    width: 45px;
    height: 56px;
    top: 1px;
    right: -100px;
    animation: float 5s ease-in-out infinite;
}

.floating-ruble__8 {
    width: 37px;
    height: 46px;
    top: 124px;
    right: -217px;
    animation: float 6s ease-in-out infinite;
}

.floating-ruble__9 {
    width: 111px;
    height: 136px;
    top: 225px;
    right: -144px;
    animation: float 5s ease-in-out infinite;
}

.floating-ruble__10 {
    width: 33px;
    height: 41px;
    top: 457px;
    right: -203px;
    animation: float 4s ease-in-out infinite;
}

.floating-rubles-wrapper {
    overflow: hidden;
}

@media screen and (max-width: 902px) {

    .floating-ruble__8.floating-ruble,
    .floating-ruble__6.floating-ruble,
    .floating-ruble__10.floating-ruble {
        display: none;
    }
}

@media screen and (max-width: 758px) {
    .floating-ruble__9.floating-ruble {
        display: none;
    }
}

@media screen and (max-width: 720px) {
    .game-area {
        width: 100%;
    }

    .game-area__header {
        padding: 0px 10px;
        width: initial;
    }

    .momgame__answer-button {
        width: 30vw;
    }

    .balloons__title {
        font-size: 35px;
    }

    .balloons__result-text {
        font-size: 35px;
    }

    .chat__answers-buttons-wrapper.momgame__answers-buttons-aligner {
        justify-content: space-between;
    }

    .momgame__answer-button.triangle:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 99%;
        left: 45%;
        border-top: 10px solid #f18e97;
        border-left: 25px solid transparent;
    }
}

@media screen and (max-width: 675px) {
    .floating-rubles-wrapper {
        display: none;
    }

    .balloons__result-text {
        width: 90%;
    }
}

@media screen and (max-height: 660px) {

    .floating-ruble__5.floating-ruble,
    .floating-ruble__10.floating-ruble {
        display: none;
    }
}

@media screen and (max-width: 620px) {

    .chat__checkbox-wrapper {
        margin-bottom: 10px;
    }

    .result .money {
        margin-top: 20px;
    }

    .result__title {
        font-size: 32px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .result__description {
        margin: 0px 20px;
    }

    .balloons__start-page__red-balloon,
    .balloons__start-page__green-balloon,
    .balloons__result-balloon1,
    .balloons__result-balloon2,
    .balloons__result-balloon3 {
        display: none;
    }

    .chat__answer-button {
        width: 45vw;
        margin: 0px;
    }

    .chat__answers-buttons-wrapper {
        justify-content: space-around;
    }

    .balloons__title,
    .balloons__description {
        margin-left: 20px;
        margin-right: 20px;
    }

    .start-page__title {
        font-size: 36px;
    }

    .momgame__image {
        height: 300px;
        width: 300px;
    }

    .start-page__title {
        margin-left: 15px;
        margin-right: 15px;
    }

    .start-page__description {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 440px) {
    .result-layout {
        height: initial;
    }
}

@media screen and (max-width: 350px) {
    .momgame__answer-button.triangle:after {
        left: 40%;
        border-top: 8px solid #f18e97;
        border-left: 17px solid transparent;
    }
}

@media screen and (max-height: 690px) {

    .integration-layout,
    .result-layout {
        height: initial;
    }

    .balloons__start-page {
        height: initial;
    }

    .balloons__title {
        margin-top: 40px;
    }

    .balloons__result-text {
        margin-top: 30px;
        width: 80%;
    }

    .balloons__next-button {
        margin-bottom: 30px;
    }

    .integration__text {
        margin-bottom: 60px;
    }

    .integration__logo {
        margin-bottom: 60px;
    }
}

@media screen and (max-height: 550px) {
    .balloons__result-wrapper {
        height: initial;
    }
}

@keyframes fadeNewMessage {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeProgressAndMoney {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeChatApp {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes typingMessage {
    0% {
        background-color: #606060;
    }

    50% {
        background-color: #fff;
    }

    60% {
        background-color: #606060;
    }
}

@-webkit-keyframes floatBubble {
    0% {
        top: 100%;
    }

    100% {
        top: -40%;
    }
}

@keyframes floatBubble {
    0% {
        top: 100%;
    }

    100% {
        top: -50%;
    }
}

@keyframes bubble-rotating {
    0% {
        -webkit-transform: translateX(0) rotate(8deg);
    }

    25% {
        -webkit-transform: rotate(4deg);
    }

    50% {
        -webkit-transform: translateX(5px) rotate(-4deg);
    }

    75% {
        -webkit-transform: translateX(-2px) rotate(4deg);
    }

    100% {
        -webkit-transform: rotate(8deg);
    }
}

@keyframes next-button-appear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

[hidden] {
    display: none;
}

.safari-after-fixer {
    height: 20px;
    width: 100%;
    position: absolute;
    bottom: -20px;
    background-color: #190940;
}

.momgame.safari-after-fixer {
    background-color: #e50050;
}